body {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat Alternates', sans-serif;
    background-color: rgb(22, 22, 22);
}

#app-container {
    margin-left: auto;
    margin-right: auto;
    min-width: 1200px;
    max-width: 1920px;
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


.fade-in {
    opacity: 0;
    -webkit-animation: fadeIn ease-in 1;
    animation: fadeIn ease-in 1;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
}

.fade-in.one {
    -webkit-animation-delay: 0.25s;
    animation-delay: 0.25s;
}

.fade-in.two {
    -webkit-animation-delay: .7s;
    animation-delay: .7s;
}

.fade-in.three {
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s;
}

.fade-in.four {
    -webkit-animation-delay: 1.7s;
    animation-delay: 1.7s;
}

@-webkit-keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.fade-out {
    opacity: 1;
    -webkit-animation: fadeOut ease-in-out 1;
    animation: fadeOut ease-in-out 1;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
}

.blackandwhite {
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
}
.nav-bar {
    min-width: 1200px;
    max-width: 1920px;
    display: flex;
    flex-direction: row;
    height: 7.1rem;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    z-index: 10;
}

.nav-logo {
    position: relative;
    display: flex;
    flex-direction: row;
    z-index: 12;
    padding: .5rem;
    margin: 1rem 2rem;
    outline: none;
    border: none;
    box-shadow: none;
    background-image:
        -webkit-linear-gradient(#d1d1d1, #d1d1d1),
        -webkit-linear-gradient(#d1d1d1, #d1d1d1),
        -webkit-linear-gradient(#d1d1d1, #d1d1d1),
        -webkit-linear-gradient(#d1d1d1, #d1d1d1),

        -webkit-linear-gradient(#16161600, #16161600),
        -webkit-linear-gradient(#16161600, #16161600),
        -webkit-linear-gradient(#16161600, #16161600),
        -webkit-linear-gradient(#16161600, #16161600);
    background-image:
        linear-gradient(#d1d1d1, #d1d1d1),
        linear-gradient(#d1d1d1, #d1d1d1),
        linear-gradient(#d1d1d1, #d1d1d1),
        linear-gradient(#d1d1d1, #d1d1d1),

        linear-gradient(#16161600, #16161600),
        linear-gradient(#16161600, #16161600),
        linear-gradient(#16161600, #16161600),
        linear-gradient(#16161600, #16161600);

    background-position: 0 0, 0 0, 0 100%, 0 100%,
        0 0, 0 0, 0 100%, 100% 0;
    background-size: .1rem 0%, 0% .1rem, 0% .1rem, .1rem 0%,
        .1rem 100%, 100% .1rem, 100% .1rem, .1rem 100%;
    background-color: transparent;
    background-repeat: no-repeat;
    -webkit-transition: 0.2s linear;
    transition: 0.2s linear;
}

.nav-logo:hover {
    background-position: 0 100%, 0 0, 0 100%, 100% 0,
        0 0, 0 0, 0 100%, 100% 0;
    background-size: .1rem 0%, 100% .1rem, 0% .1rem, .1rem 0%,
        .1rem 100%, 100% .1rem, 100% .1rem, .1rem 100%;
    -webkit-animation: animate 1.4s linear infinite 0.2s;
            animation: animate 1.4s linear infinite 0.2s;
}

@-webkit-keyframes animate {
    0% {
        background-position: 0 100%, 0 0, 0 100%, 100% 0,
            0 0, 0 0, 0 100%, 100% 0;
        background-size: .1rem 0%, 100% .1rem, 0% .1rem, .1rem 0%,
            .1rem 100%, 100% .1rem, 100% .1rem, .1rem 100%;
    }

    40% {
        background-position: 0 100%, 100% 0, 100% 100%, 100% 0,
            0 0, 0 0, 0 100%, 100% 0;
        background-size: .1rem 0%, 100% .1rem, 0% .1rem, .1rem 100%,
            .1rem 100%, 100% .1rem, 100% .1rem, .1rem 100%;
    }

    60% {
        background-position: 0 100%, 100% 0, 100% 100%, 100% 100%,
            0 0, 0 0, 0 100%, 100% 0;
        background-size: .1rem 0%, 0% .1rem, 100% .1rem, .1rem 100%,
            .1rem 100%, 100% .1rem, 100% .1rem, .1rem 100%;
    }

    70% {
        background-position: 0 100%, 100% 0, 0% 100%, 100% 100%,
            0 0, 0 0, 0 100%, 100% 0;
        background-size: .1rem 100%, 0% .1rem, 100% .1rem, .1rem 0%,
            .1rem 100%, 100% .1rem, 100% .1rem, .1rem 100%;
    }

    80% {
        background-position: 0% 0%, 0% 0, 0% 100%, 100% 100%,
            0 0, 0 0, 0 100%, 100% 0;
        background-size: .1rem 100%, 0% .1rem, 0% .1rem, .1rem 0%,
            .1rem 100%, 100% .1rem, 100% .1rem, .1rem 100%;
    }

    100% {
        background-position: 0% 0%, 0 0, 0 100%, 100% 100%,
            0 0, 0 0, 0 100%, 100% 0;
        background-size: .1rem 0%, 100% .1rem, 0% .1rem, .1rem 0%,
            .1rem 100%, 100% .1rem, 100% .1rem, .1rem 100%;
    }
}

@keyframes animate {
    0% {
        background-position: 0 100%, 0 0, 0 100%, 100% 0,
            0 0, 0 0, 0 100%, 100% 0;
        background-size: .1rem 0%, 100% .1rem, 0% .1rem, .1rem 0%,
            .1rem 100%, 100% .1rem, 100% .1rem, .1rem 100%;
    }

    40% {
        background-position: 0 100%, 100% 0, 100% 100%, 100% 0,
            0 0, 0 0, 0 100%, 100% 0;
        background-size: .1rem 0%, 100% .1rem, 0% .1rem, .1rem 100%,
            .1rem 100%, 100% .1rem, 100% .1rem, .1rem 100%;
    }

    60% {
        background-position: 0 100%, 100% 0, 100% 100%, 100% 100%,
            0 0, 0 0, 0 100%, 100% 0;
        background-size: .1rem 0%, 0% .1rem, 100% .1rem, .1rem 100%,
            .1rem 100%, 100% .1rem, 100% .1rem, .1rem 100%;
    }

    70% {
        background-position: 0 100%, 100% 0, 0% 100%, 100% 100%,
            0 0, 0 0, 0 100%, 100% 0;
        background-size: .1rem 100%, 0% .1rem, 100% .1rem, .1rem 0%,
            .1rem 100%, 100% .1rem, 100% .1rem, .1rem 100%;
    }

    80% {
        background-position: 0% 0%, 0% 0, 0% 100%, 100% 100%,
            0 0, 0 0, 0 100%, 100% 0;
        background-size: .1rem 100%, 0% .1rem, 0% .1rem, .1rem 0%,
            .1rem 100%, 100% .1rem, 100% .1rem, .1rem 100%;
    }

    100% {
        background-position: 0% 0%, 0 0, 0 100%, 100% 100%,
            0 0, 0 0, 0 100%, 100% 0;
        background-size: .1rem 0%, 100% .1rem, 0% .1rem, .1rem 0%,
            .1rem 100%, 100% .1rem, 100% .1rem, .1rem 100%;
    }
}

.nav-logo img, 
.nav-logo h1 {
    -webkit-filter: brightness(.60);
            filter: brightness(.60);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.nav-logo:hover h1, 
.nav-logo:hover img {
    -webkit-filter: brightness(.80);
            filter: brightness(.80);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.nav-bar-buttons {
    display: flex;
    justify-content: flex-end;
    margin: 1rem 2rem;
    margin-left: auto;
}

.nav-logo img {
    margin-left: .5rem;
}

.nav-logo h1 {
    color: rgb(255, 255, 255);
    font-size: 2.5rem;
    margin: .3rem .5rem;
    text-align: center;
    font-family: 'Montserrat Alternates';
}

.nav-bar a {
    text-decoration: none;
}

.nav-bar-buttons>a {
    position: relative;
    color: rgb(150, 150, 150);
    font-size: 1.2rem;
    margin: 1.3rem 1rem;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
}

.nav-bar-buttons>a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: .1rem;
    bottom: 1.6rem;
    left: 0;
    background-color: rgb(150, 150, 150);
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.nav-bar-buttons>a:hover {
    color: #d6d6d6;
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.nav-bar-buttons>a:hover:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}
#home-container {
    min-width: 800px;
    max-width: 1200px;
}

.background {
    z-index:11;
}

.home-nav-bar {
    display: flex;
    flex-direction: row;
    height: 7rem;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    z-index: 13;
}

.home-nav-bar a {
    text-decoration: none;
}

.home-nav-logo {
    border: .1rem solid rgb(180, 180, 180);
    background: rgba(22, 22, 22, 0.753);    
    padding: .4rem;
    min-height: 2.5rem;
    position: relative;
    display: flex;
    flex-direction: row;
    z-index: 12;
    margin: 1rem 2rem;
}

.home-nav-logo img {
    -webkit-filter: brightness(.80);
            filter: brightness(.80);
    margin: 0 .5rem;
}

.home-nav-logo h1 {
    height: 2.5rem;
    color: rgb(180, 180, 180);
    font-size: 2.5rem;
    margin: .3rem .3rem 0 0;
    text-align: center;
    font-family: 'Montserrat Alternates';
}

.wrapper a,
.dot-1,
.dot-2 {
    z-index: 12;
}

.btn {
    position: absolute;
    z-index: 10;
}

.wrapper>a {
    position: absolute;
    color: rgb(150, 150, 150);
    font-size: 5rem;
    cursor: pointer;
    text-decoration: none;
}

.wrapper>a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: .2rem;
    bottom: 2.5rem;
    left: 0;
    background-color: #d6d6d6;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.wrapper>a:hover {
    color: #d6d6d6;
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.wrapper>a:hover:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

#btn-1 {
    bottom: 9rem;
    right: 43rem;
}

#btn-2 {
    bottom: 9rem;
    right: 2rem;
}

#btn-3 {
    bottom: 2rem;
    right: 29rem;
}

#btn-4 {
    bottom: 2rem;
    right: 2rem;
}

.background {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: fixed;
    width: 115vw;
    height: 115vh;
    -webkit-transition: width .35s ease, height .35s ease;
    transition: width .35s ease, height .35s ease;
}

#content-0 {
    opacity: 1;
    background: rgb(22, 22, 22);
}

#content-1,
#content-2,
#content-3,
#content-4 {
    opacity: 0;
    -webkit-transition: all .5s ease-in-out;
}

#content-1 {
    background-image: url(/static/media/about_michael.25e7dc16.jpg);
}

#content-2 {
    background-image: url(/static/media/computer_code.1980b5ac.jpg);
}

#content-3 {
    background-image: url(/static/media/michael-blue-drums.8e3a0e39.jpg);
}

#content-4 {
    background-image: url(/static/media/denver.1be56e07.jpg);
}

.btn:hover~.background {
    width: 101vw;
    height: 101vh;
}

#btn-1:hover~#content-1,
#btn-2:hover~#content-2,
#btn-3:hover~#content-3,
#btn-4:hover~#content-4 {
    opacity: 1;
    -webkit-transition: all .5s ease-in-out;
}

.dot-1 {
    height: 2rem;
    width: 2rem;
    background-color: rgb(126, 126, 126);
    border-radius: 50%;
    position: absolute;
    bottom: 10.6rem;
    right: 38.3rem;
}

.dot-2 {
    height: 2rem;
    width: 2rem;
    background-color: rgb(126, 126, 126);
    border-radius: 50%;
    position: absolute;
    bottom: 3.6rem;
    right: 24rem;
}
#about-body {
    position: inline;
    min-width: 1200px;
    max-width: 1920px;
    width: 100%;
}

#about-info {
    position: relative;
    margin: 8rem 3rem 3rem 3rem;
    width: 40%;
    z-index: 15;
}

#about-info h1 {
    color: rgb(93, 38, 165);
    text-align: left;
    font-family: 'Lato', sans-serif;
    font-size: 4.7rem;
    margin: 4rem 0 2rem 0;
    width: 100%;
}

#about-info p {
    color: rgb(150, 150, 150);
    z-index: 15;
    font-size: 1.2rem;
}

.about-michael-img {
    top:0;
    position: absolute;
    background-size: cover;
    min-width: 1200px;
    max-width: 1920px;
    width: 100%;
    -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
}

.about-download-container {
    margin: 1rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    /* width: 26rem; */
}

.about-download-container a {
    text-decoration: none;
    color: rgb(150, 150, 150);
    margin: 1rem 0;
}

.about-download-container a:hover {
    color: rgb(211, 211, 211);
}

.about-download-text {
    margin: 0 5rem;
}

.about-download-text h3 {
    font-family: 'Lato', sans-serif;
    font-size: 1.5rem;
    margin: .5rem 0;
}

.about-icon {
    background-color: rgb(93, 38, 165);
    border-radius: 50%;
    text-align: center;
    font-size: 1.5rem;
    padding: 1.1rem;
    position: absolute;
    width: 1.5rem;
    margin-top: .5rem;
}
 #development-container {
    overflow: hidden;
}

#development-header-container {
    width: 100%;
    min-width: 1200px;
    max-width: 1920px;
    height: 38rem;
}

#development-header {
    width: 100%;
    height: 50rem;
}

#development-header-title-info-container {
    position: absolute;
    top: 0;
    max-width: 1920px;
    min-width: 800px;
    width: 100%;
}

#development-header-title {
    width: 40rem;
}

#development-header-title h1 {
    position: absolute;
    margin: 15rem 3rem;
    color: #B7C475;
    font-family: 'Lato', sans-serif;
    font-size: 5rem;
    z-index: 13;
}

#development-header-background {
    background-image: url(/static/media/typing.758691e1.gif);
    width: 100%;
    min-width: 1200px;
    max-width: 1920px;
    height: 50rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    -webkit-filter: grayscale(100%) brightness(60%);
            filter: grayscale(100%) brightness(60%);
}

.development-header-background-text {
    font-size: 26rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
}

#development-header-info {
    position: relative;
    background-color: rgba(0, 0, 0, 0.50);
    border: .1rem solid #B7C475;
    top: 25rem;
    width: 60rem;
    height: 16rem;
    padding: 2rem;
    margin-left: auto;
    margin-right: auto;
}

#development-header-info h2 {
    position: relative;
    color: #B7C475;
    text-align: left;
    font-family: 'Lato', sans-serif;
    font-size: 1.5rem;
    margin: 0;
}

#development-header-info p {
    color: rgb(150, 150, 150);
    font-size: 1.3rem;
    margin: .8rem;
    margin-bottom: 2rem;
}

#development-header-info img {
    display: flex;
    justify-content: center;
}

#development-header-skills {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 60rem;
    height: 7rem;
    margin-left: auto;
    margin-right: auto;
}

.dev-skill {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    margin: .5rem;
    width: 5rem;
    height: 6rem;
}

.dev-skill img {
    margin-left: auto;
    margin-right: auto;
    height: 50px;
}

.dev-skill h4 {
    color: #B7C475;
    text-align: center;
    margin: .5rem;
    font-size: .7rem;
    font-family: 'Lato', sans-serif;
    height: 3rem;
}

#development-body {
    position: relative;
    justify-content: center;
}

#development-projects-container {
    margin-top: 13rem;
    margin-bottom: 5rem;
    justify-content: center;
}

#development-projects-container h1 {
    font-family: 'Lato', sans-serif;
    color: rgba(183, 196, 117);
    font-size: 2.5rem;
    margin: 2rem 10rem;
}

#development-projects {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: 10%;
    margin-left: 10%;
}

figure.project-tile {
    display: inline-block;
    color: #fff;
    position: relative;
    overflow: hidden;
    margin: .6rem .7rem;
    width: 25rem;
    height: 18rem;
    background: #000000;
    text-align: center;
    border: .1rem solid rgba(183, 196, 117);
    cursor: pointer;
}

figure.project-tile * {
    box-sizing: border-box;
}

figure.project-tile img {
    opacity: 1;
    width: 100%;
    -webkit-transition: opacity 0.35s;
    transition: opacity 0.35s;
}

figure.project-tile figcaption {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: left;
}

figure.project-tile figcaption>div {
    float: left;
    height: 100%;
    overflow: hidden;
    width: 50%;
    position: relative;
}

figure.project-tile figcaption::before {
    position: absolute;
    top: 50%;
    bottom: 50%;
    left: 50%;
    width: 2px;
    content: '';
    opacity: 0;
    background-color: rgba(255, 255, 255, 0);
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s;
}

figure.project-tile h2,
figure.project-tile h3,
figure.project-tile h4,
figure.project-tile p {
    margin: 0;
    padding: 20px;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    -webkit-transition: opacity 0.45s, -webkit-transform 0.45s;
    transition: opacity 0.45s, -webkit-transform 0.45s, transform 0.45s;
}

figure.project-tile h2 {
    font-family: 'Lato', sans-serif;
    text-align: right;
    display: inline-block;
    word-spacing: -0.1em;
    font-weight: 300;
    text-transform: uppercase;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    -webkit-transform: translate3d(50%, 0%, 0);
    transform: translate3d(50%, 0%, 0);
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

figure.project-tile h3 {
    height: 3rem;
    width: 5rem;
    margin-top: 14rem;
    margin-left: 7.6rem;
    padding: .5rem;
    border: .1rem solid #c8c8c8;
    border-right: 0;
    font-family: 'Lato', sans-serif;
    text-align: right;
    display: inline-block;
    word-spacing: -0.1em;
    font-size: 1.3rem;
    font-weight: 300;
    text-transform: uppercase;
    background: rgba(0, 0, 0, 0.5);
    -webkit-transform: translate3d(50%, 0%, 0);
    transform: translate3d(50%, 0%, 0);
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

figure.project-tile h4 {
    height: 3rem;
    width: 4.5rem;
    margin-top: 14rem;
    padding: .5rem;
    border: .1rem solid #c8c8c8;
    border-left: 0;
    font-family: 'Lato', sans-serif;
    text-align: left;
    display: block;
    bottom: 0;
    text-align: left;
    font-weight: 300;
    font-size: 1.3rem;
    top: 0%;
    background: rgba(0, 0, 0, 0.5);
    -webkit-transform: translate3d(-50%, 0%, 0);
    transform: translate3d(-50%, 0%, 0);
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

figure.project-tile h2 span {
    font-weight: 800;
}

figure.project-tile p {
    display: block;
    bottom: 0;
    text-align: left;
    font-weight: 300;
    font-size: 1rem;
    top: 0%;
    color: #000;
    background: rgba(183, 196, 117, 0.849);
    -webkit-transform: translate3d(-50%, 0%, 0);
    transform: translate3d(-50%, 0%, 0);
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

figure.project-tile:hover img {
    opacity: 0.7;
}

figure.project-tile:hover figcaption h2,
figure.project-tile:hover figcaption h3,
figure.project-tile:hover figcaption h4,
figure.project-tile:hover figcaption p {
    -webkit-transform: translate3d(0%, 0%, 0);
    transform: translate3d(0%, 0%, 0);
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
}

figure.project-tile:hover figcaption h2 {
    opacity: 1;
}

figure.project-tile:hover figcaption h3 {
    opacity: 1;
}

figure.project-tile:hover figcaption h4 {
    opacity: 1;
}

figure.project-tile:hover figcaption p {
    opacity: 1;
}

figure.project-tile:hover figcaption::before {
    background: #ffffff;
    top: 0px;
    bottom: 0px;
    opacity: 1;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

#development-modal {
    position: fixed;
    max-width: 1920px;
    min-width: 800px;
    width: 100%;
    height: 100%;
    z-index: 100;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.397);
}

#development-modal-body {
    position: relative;
    height: 90%;
    width: 90%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    border: .1rem solid rgba(183, 196, 117);
    background-color: #0f0f0f;
    overflow: auto;
}

#development-modal-info {
    margin: 3rem;
    margin-right: 7rem;
}

.close-button {
    top: 6%;
    right: 6%;
    position: fixed;
    z-index: 10;
}

.close-button h3 {
    font-family: 'Lato', sans-serif;
    font-size: 1.5rem;
    margin: 1rem;
    color: rgba(183, 196, 117);
    background-color: #0f0f0f;
    cursor: pointer;
}

.close-button h3:hover {
    color: rgb(212, 228, 131);
}

#development-modal-image {
    margin-left: .5rem;
}

#development-modal-image img{
    width: 600px;
    margin: 1rem;
    border: .1rem solid rgb(187, 187, 187);
}

#development-modal-body h1 {
    font-family: 'Lato', sans-serif;
    font-size: 4rem;
    text-transform: uppercase;
    margin: 1rem;
    color: rgba(183, 196, 117);
}

#development-modal-body h2 {
    font-family: 'Lato', sans-serif;
    font-size: 2rem;
    margin: 1rem;
    color: rgba(183, 196, 117);
}

#development-modal-info p {
    color: #cccccc;
    font-size: 1.2rem;
    margin: 1rem;
}

#development-modal-technologies {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: .5rem;
    margin-top: 2rem; 
}

#modal-other-technologies li {
    color: #cccccc;
    font-size: 1.2rem; 
}

#modal-icons-container {
    display: flex;
    flex-direction: row;
    margin-left: .5rem;
}

.modal-icons {
    display: flex;
    margin: 1.2rem;
    justify-content: center;
}

.modal-icon {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 2rem;
}

.modal-icon:hover {
    background-color: rgb(212, 228, 131);
}

.modal-icons a {
    display: flex;
    flex-direction: column;
    text-decoration: none;
}

.modal-icons i {
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(183, 196, 117);
    border-radius: 50%;
    text-align: center;
    font-size: 2rem;
    padding: .6rem;
    color: #161616;
}

.modal-icons h4 {
    color: rgba(183, 196, 117);
    text-decoration: none;
    font-size: .6rem;
    margin-top: .5rem;
    text-align: center;
}

.modal-icons a:hover > .modal-icon {
    background-color: rgb(212, 228, 131);
}
#music-header {
    width: 100%;
    height: 30rem;
}

#music-header h1 {
    position: absolute;
    color: #70a9a1;
    font-family: 'Lato', sans-serif;
    font-size: 7rem;
    margin: 15rem 3.5rem;
    z-index: 10;
}

#music-header-background {
  background-image : url(/static/media/michael_drumming.931ee98c.gif);
  width: 100%;
  height: 40rem;
  min-width: 1200px;
  max-width: 1920px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0,0,0,0));
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}

.music-header-background-text {
  font-size:25rem;
  font-family: 'Lato', sans-serif;
  font-weight: bold;
}


#music-body {
    margin: 2rem;
}

#music-body h1 {
    color: #70a9a1;
    text-align: left;
    font-family: 'Lato', sans-serif;
    margin: 1rem 0;
}

#track-info-container {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto; 
}

#music-tracks-container {
    padding: 1rem;
    width: 50%;
}

#music-media-tracks {
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 750px;
}

.music-track {
    max-width: 700px;
}

#music-info {
    margin: .5rem 2rem 1.5rem 1rem;
    width: 50%;
    padding: .5rem;
}

#music-info p {
    color: #acacac;
    font-size: 1rem;
}

#music-info img {
    width: 100%;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
}

#music-info img:hover {
    -webkit-filter: none;
            filter: none;
}

#music-info-image {
    border: .1rem solid #70a9a1;
    margin: 2rem 0;
}

#music-albums-container {
    border-top: .05rem solid #70a9a1;
    padding: 1rem;
    margin: 1rem 0;
    justify-content: center;
}

#music-albums {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: 10%;
    margin-left:10%;
}

.music-media-audio {
    margin: 1rem;
}

.music-media-audio a {
    text-decoration: none;
}

#music-body h2:hover {
    color: rgb(189, 189, 189);
}

#music-body h2 {
    font-size: 1rem;
    margin: .5rem 0;
    color: rgb(128, 128, 128);
    font-family: 'Lato', sans-serif;
}

iframe {
    border: 0;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    background-image: url(/static/media/loading.2da42737.gif);   
    background-repeat: no-repeat;
    background-size: 42px;
    background-position: center;
    background-color: rgb(32, 32, 32);
}

iframe:hover {
    -webkit-filter: none;
            filter: none;
}

#music-video-container {
    border-top: .05rem solid #70a9a1;
    padding: 1rem;
    margin-right: auto;
    margin-left: auto;
}

#music-videos {
    display: flex;
    flex-direction: row;
}

.music-media-video {
	position:relative;
	padding-bottom:25.5%;
	padding-top:30px;
	height:0;
    overflow:hidden;
    width: 50%;
    margin: 1rem;
}

.music-media-video iframe, 
.video-container object, 
.video-container embed {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
}
#contact-header-container {
    position: relative;
}

#contact-header {
    position: relative;
    height: 80rem;
}

#contact-header img {
    position: inline;
    width: 100%;
    height: 100%;
    -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
}

#contact-header h1 {
    top: 11rem;
    position: absolute;
    color: #40798C;
    text-align: left;
    font-family: 'Lato', sans-serif;
    font-size: 7rem;
    margin: 5rem;
    width: 30rem;
}

#contact-header p {
    position: absolute;
    color: rgb(155, 155, 155);
    font-size: 4.5rem;
    font-weight: 600;
    font-style: italic;
    top: 8rem;
    margin-left: 50%;
    margin-right: 5rem;
    text-align: right;
}

#contact-info {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 33rem;
    min-width: 800px;
    max-width: 1920px;
    width: 100%;
}

#contact-form {
    width: 50%;
    margin-right: auto;
    margin-left: auto;
}

#contact-form form {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 5rem;
    margin-right: auto;
    margin-left: auto;
    min-width: 400px;
    max-width: 800px;
    width: 100%;
}

#contact-form h1 {
    color: rgb(185, 185, 185);
}

#contact-form input,
textarea {
    font-family: 'Montserrat Alternates', sans-serif;
    background-color: rgb(211, 211, 211);
    border: .2rem solid #40798C;
    border-radius: .1rem;
    margin: .25rem 0;
    color: rgb(17, 17, 17);
    font-size: 1rem;
    outline: none;
    padding: .5rem;
}

::-webkit-input-placeholder { 
    color: rgb(17, 17, 17);
    opacity: 1; 
}

::-ms-input-placeholder { 
    color: rgb(17, 17, 17);
    opacity: 1; 
}

::placeholder { 
    color: rgb(17, 17, 17);
    opacity: 1; 
}

#contact-form input {
    height: 1.7rem;
}

#contact-form textarea {
    height: 20rem;
}

#contact-form button {
    background-color: #40798C;
    border-radius: .1rem;
    border: none;
    height: 3rem;
    color: rgb(221, 221, 221);
    font-family: 'Lato', sans-serif;
    font-size: 1.5rem;
    margin: .25rem 0;
}

#contact-form button:hover {
    background-color: rgb(47, 105, 124);
    cursor: pointer;
}

.vertical-line {
    border-left: .15rem solid #40798C;
    height: 29rem;
    margin-top: 9rem;
    margin-right: auto;
    margin-left: auto;
}

.contact-method {
    color: rgb(184, 184, 184);
    margin: 7.5rem 0;
    width: 40%;
}

.contact-method-info {
    margin: 3rem;
    display: flex;
    flex-direction: row;
}

.contact-method-info a {
    text-decoration: none;
    color: rgb(184, 184, 184);
}

.contact-method-info a:hover {
    color: rgb(224, 224, 224);
}

.contact-method-info-text {
    margin: 0 5rem;
}

.contact-method-info-text h3 {
    font-family: 'Lato', sans-serif;
    font-size: 1.5rem;
    margin: .5rem 0;
    text-transform: uppercase;
}

.icon {
    background-color: #40798C;
    border-radius: 50%;
    text-align: center;
    font-size: 1.5rem;
    padding: 1.1rem;
    position: absolute;
    width: 1.5rem;
    margin-top: .5rem;
}
#footer {
    display: flex;
    justify-content: space-between;
    height: 4rem;
    position: relative;
    margin: auto;
    min-width: 1200px;
    max-width: 1920px;
    z-index: 10;
    border-top: .05rem solid rgb(36, 35, 35); 
}

#footer-icons {
    margin: 1.3rem;

}

.footer-icon i {
    background-color: rgb(94, 94, 94);
    border-radius: 50%;
    text-align: center;
    font-size: 1.2rem;
    padding: .8rem;
    position: block;
    margin: 0 .5rem;
    text-decoration: none;
    color: rgb(180, 180, 180);
}

.footer-icon i:hover {
    background-color: rgb(119, 119, 119);
    color: rgb(216, 216, 216);
}

.copyright p{
    color: rgb(124, 124, 124);
    margin:  2rem 2.5rem;
    display: flex;
    right: 0;
}
