body {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat Alternates', sans-serif;
    background-color: rgb(22, 22, 22);
}

#app-container {
    margin-left: auto;
    margin-right: auto;
    min-width: 1200px;
    max-width: 1920px;
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


.fade-in {
    opacity: 0;
    -webkit-animation: fadeIn ease-in 1;
    -moz-animation: fadeIn ease-in 1;
    animation: fadeIn ease-in 1;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    animation-duration: 1s;
}

.fade-in.one {
    -webkit-animation-delay: 0.25s;
    -moz-animation-delay: 0.25s;
    animation-delay: 0.25s;
}

.fade-in.two {
    -webkit-animation-delay: .7s;
    -moz-animation-delay: .7s;
    animation-delay: .7s;
}

.fade-in.three {
    -webkit-animation-delay: 1.2s;
    -moz-animation-delay: 1.2s;
    animation-delay: 1.2s;
}

.fade-in.four {
    -webkit-animation-delay: 1.7s;
    -moz-animation-delay: 1.7s;
    animation-delay: 1.7s;
}

@-webkit-keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@-moz-keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.fade-out {
    opacity: 1;
    -webkit-animation: fadeOut ease-in-out 1;
    -moz-animation: fadeOut ease-in-out 1;
    animation: fadeOut ease-in-out 1;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    animation-duration: 1s;
}

.blackandwhite {
    filter: grayscale(100%);
}