#home-container {
    min-width: 800px;
    max-width: 1200px;
}

.background {
    z-index:11;
}

.home-nav-bar {
    display: flex;
    flex-direction: row;
    height: 7rem;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    z-index: 13;
}

.home-nav-bar a {
    text-decoration: none;
}

.home-nav-logo {
    border: .1rem solid rgb(180, 180, 180);
    background: rgba(22, 22, 22, 0.753);    
    padding: .4rem;
    min-height: 2.5rem;
    position: relative;
    display: flex;
    flex-direction: row;
    z-index: 12;
    margin: 1rem 2rem;
}

.home-nav-logo img {
    filter: brightness(.80);
    margin: 0 .5rem;
}

.home-nav-logo h1 {
    height: 2.5rem;
    color: rgb(180, 180, 180);
    font-size: 2.5rem;
    margin: .3rem .3rem 0 0;
    text-align: center;
    font-family: 'Montserrat Alternates';
}

.wrapper a,
.dot-1,
.dot-2 {
    z-index: 12;
}

.btn {
    position: absolute;
    z-index: 10;
}

.wrapper>a {
    position: absolute;
    color: rgb(150, 150, 150);
    font-size: 5rem;
    cursor: pointer;
    text-decoration: none;
}

.wrapper>a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: .2rem;
    bottom: 2.5rem;
    left: 0;
    background-color: #d6d6d6;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.wrapper>a:hover {
    color: #d6d6d6;
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.wrapper>a:hover:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

#btn-1 {
    bottom: 9rem;
    right: 43rem;
}

#btn-2 {
    bottom: 9rem;
    right: 2rem;
}

#btn-3 {
    bottom: 2rem;
    right: 29rem;
}

#btn-4 {
    bottom: 2rem;
    right: 2rem;
}

.background {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 115vw;
    height: 115vh;
    transition: width .35s ease, height .35s ease;
}

#content-0 {
    opacity: 1;
    background: rgb(22, 22, 22);
}

#content-1,
#content-2,
#content-3,
#content-4 {
    opacity: 0;
    -webkit-transition: all .5s ease-in-out;
}

#content-1 {
    background-image: url('../assets/about_michael.jpg');
}

#content-2 {
    background-image: url('../assets/computer_code.jpg');
}

#content-3 {
    background-image: url('../assets/michael-blue-drums.jpg');
}

#content-4 {
    background-image: url('../assets/denver.jpg');
}

.btn:hover~.background {
    width: 101vw;
    height: 101vh;
}

#btn-1:hover~#content-1,
#btn-2:hover~#content-2,
#btn-3:hover~#content-3,
#btn-4:hover~#content-4 {
    opacity: 1;
    -webkit-transition: all .5s ease-in-out;
}

.dot-1 {
    height: 2rem;
    width: 2rem;
    background-color: rgb(126, 126, 126);
    border-radius: 50%;
    position: absolute;
    bottom: 10.6rem;
    right: 38.3rem;
}

.dot-2 {
    height: 2rem;
    width: 2rem;
    background-color: rgb(126, 126, 126);
    border-radius: 50%;
    position: absolute;
    bottom: 3.6rem;
    right: 24rem;
}