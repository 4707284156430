#music-header {
    width: 100%;
    height: 30rem;
}

#music-header h1 {
    position: absolute;
    color: #70a9a1;
    font-family: 'Lato', sans-serif;
    font-size: 7rem;
    margin: 15rem 3.5rem;
    z-index: 10;
}

#music-header-background {
  background-image : url('../assets/michael_drumming.gif');
  width: 100%;
  height: 40rem;
  min-width: 1200px;
  max-width: 1920px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0,0,0,0));
  filter: grayscale(100%);
}

.music-header-background-text {
  font-size:25rem;
  font-family: 'Lato', sans-serif;
  font-weight: bold;
}


#music-body {
    margin: 2rem;
}

#music-body h1 {
    color: #70a9a1;
    text-align: left;
    font-family: 'Lato', sans-serif;
    margin: 1rem 0;
}

#track-info-container {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto; 
}

#music-tracks-container {
    padding: 1rem;
    width: 50%;
}

#music-media-tracks {
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 750px;
}

.music-track {
    max-width: 700px;
}

#music-info {
    margin: .5rem 2rem 1.5rem 1rem;
    width: 50%;
    padding: .5rem;
}

#music-info p {
    color: #acacac;
    font-size: 1rem;
}

#music-info img {
    width: 100%;
    filter: grayscale(100%);
}

#music-info img:hover {
    filter: none;
}

#music-info-image {
    border: .1rem solid #70a9a1;
    margin: 2rem 0;
}

#music-albums-container {
    border-top: .05rem solid #70a9a1;
    padding: 1rem;
    margin: 1rem 0;
    justify-content: center;
}

#music-albums {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: 10%;
    margin-left:10%;
}

.music-media-audio {
    margin: 1rem;
}

.music-media-audio a {
    text-decoration: none;
}

#music-body h2:hover {
    color: rgb(189, 189, 189);
}

#music-body h2 {
    font-size: 1rem;
    margin: .5rem 0;
    color: rgb(128, 128, 128);
    font-family: 'Lato', sans-serif;
}

iframe {
    border: 0;
    filter: grayscale(100%);
    background-image: url("../assets/loading.gif");   
    background-repeat: no-repeat;
    background-size: 42px;
    background-position: center;
    background-color: rgb(32, 32, 32);
}

iframe:hover {
    filter: none;
}

#music-video-container {
    border-top: .05rem solid #70a9a1;
    padding: 1rem;
    margin-right: auto;
    margin-left: auto;
}

#music-videos {
    display: flex;
    flex-direction: row;
}

.music-media-video {
	position:relative;
	padding-bottom:25.5%;
	padding-top:30px;
	height:0;
    overflow:hidden;
    width: 50%;
    margin: 1rem;
}

.music-media-video iframe, 
.video-container object, 
.video-container embed {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
}