#contact-header-container {
    position: relative;
}

#contact-header {
    position: relative;
    height: 80rem;
}

#contact-header img {
    position: inline;
    width: 100%;
    height: 100%;
    -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    filter: grayscale(100%);
}

#contact-header h1 {
    top: 11rem;
    position: absolute;
    color: #40798C;
    text-align: left;
    font-family: 'Lato', sans-serif;
    font-size: 7rem;
    margin: 5rem;
    width: 30rem;
}

#contact-header p {
    position: absolute;
    color: rgb(155, 155, 155);
    font-size: 4.5rem;
    font-weight: 600;
    font-style: italic;
    top: 8rem;
    margin-left: 50%;
    margin-right: 5rem;
    text-align: right;
}

#contact-info {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 33rem;
    min-width: 800px;
    max-width: 1920px;
    width: 100%;
}

#contact-form {
    width: 50%;
    margin-right: auto;
    margin-left: auto;
}

#contact-form form {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 5rem;
    margin-right: auto;
    margin-left: auto;
    min-width: 400px;
    max-width: 800px;
    width: 100%;
}

#contact-form h1 {
    color: rgb(185, 185, 185);
}

#contact-form input,
textarea {
    font-family: 'Montserrat Alternates', sans-serif;
    background-color: rgb(211, 211, 211);
    border: .2rem solid #40798C;
    border-radius: .1rem;
    margin: .25rem 0;
    color: rgb(17, 17, 17);
    font-size: 1rem;
    outline: none;
    padding: .5rem;
}

::placeholder { 
    color: rgb(17, 17, 17);
    opacity: 1; 
}

#contact-form input {
    height: 1.7rem;
}

#contact-form textarea {
    height: 20rem;
}

#contact-form button {
    background-color: #40798C;
    border-radius: .1rem;
    border: none;
    height: 3rem;
    color: rgb(221, 221, 221);
    font-family: 'Lato', sans-serif;
    font-size: 1.5rem;
    margin: .25rem 0;
}

#contact-form button:hover {
    background-color: rgb(47, 105, 124);
    cursor: pointer;
}

.vertical-line {
    border-left: .15rem solid #40798C;
    height: 29rem;
    margin-top: 9rem;
    margin-right: auto;
    margin-left: auto;
}

.contact-method {
    color: rgb(184, 184, 184);
    margin: 7.5rem 0;
    width: 40%;
}

.contact-method-info {
    margin: 3rem;
    display: flex;
    flex-direction: row;
}

.contact-method-info a {
    text-decoration: none;
    color: rgb(184, 184, 184);
}

.contact-method-info a:hover {
    color: rgb(224, 224, 224);
}

.contact-method-info-text {
    margin: 0 5rem;
}

.contact-method-info-text h3 {
    font-family: 'Lato', sans-serif;
    font-size: 1.5rem;
    margin: .5rem 0;
    text-transform: uppercase;
}

.icon {
    background-color: #40798C;
    border-radius: 50%;
    text-align: center;
    font-size: 1.5rem;
    padding: 1.1rem;
    position: absolute;
    width: 1.5rem;
    margin-top: .5rem;
}