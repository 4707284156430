.nav-bar {
    min-width: 1200px;
    max-width: 1920px;
    display: flex;
    flex-direction: row;
    height: 7.1rem;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    z-index: 10;
}

.nav-logo {
    position: relative;
    display: flex;
    flex-direction: row;
    z-index: 12;
    padding: .5rem;
    margin: 1rem 2rem;
    outline: none;
    border: none;
    box-shadow: none;
    background-image:
        linear-gradient(#d1d1d1, #d1d1d1),
        linear-gradient(#d1d1d1, #d1d1d1),
        linear-gradient(#d1d1d1, #d1d1d1),
        linear-gradient(#d1d1d1, #d1d1d1),

        linear-gradient(#16161600, #16161600),
        linear-gradient(#16161600, #16161600),
        linear-gradient(#16161600, #16161600),
        linear-gradient(#16161600, #16161600);

    background-position: 0 0, 0 0, 0 100%, 0 100%,
        0 0, 0 0, 0 100%, 100% 0;
    background-size: .1rem 0%, 0% .1rem, 0% .1rem, .1rem 0%,
        .1rem 100%, 100% .1rem, 100% .1rem, .1rem 100%;
    background-color: transparent;
    background-repeat: no-repeat;
    transition: 0.2s linear;
}

.nav-logo:hover {
    background-position: 0 100%, 0 0, 0 100%, 100% 0,
        0 0, 0 0, 0 100%, 100% 0;
    background-size: .1rem 0%, 100% .1rem, 0% .1rem, .1rem 0%,
        .1rem 100%, 100% .1rem, 100% .1rem, .1rem 100%;
    animation: animate 1.4s linear infinite 0.2s;
}

@keyframes animate {
    0% {
        background-position: 0 100%, 0 0, 0 100%, 100% 0,
            0 0, 0 0, 0 100%, 100% 0;
        background-size: .1rem 0%, 100% .1rem, 0% .1rem, .1rem 0%,
            .1rem 100%, 100% .1rem, 100% .1rem, .1rem 100%;
    }

    40% {
        background-position: 0 100%, 100% 0, 100% 100%, 100% 0,
            0 0, 0 0, 0 100%, 100% 0;
        background-size: .1rem 0%, 100% .1rem, 0% .1rem, .1rem 100%,
            .1rem 100%, 100% .1rem, 100% .1rem, .1rem 100%;
    }

    60% {
        background-position: 0 100%, 100% 0, 100% 100%, 100% 100%,
            0 0, 0 0, 0 100%, 100% 0;
        background-size: .1rem 0%, 0% .1rem, 100% .1rem, .1rem 100%,
            .1rem 100%, 100% .1rem, 100% .1rem, .1rem 100%;
    }

    70% {
        background-position: 0 100%, 100% 0, 0% 100%, 100% 100%,
            0 0, 0 0, 0 100%, 100% 0;
        background-size: .1rem 100%, 0% .1rem, 100% .1rem, .1rem 0%,
            .1rem 100%, 100% .1rem, 100% .1rem, .1rem 100%;
    }

    80% {
        background-position: 0% 0%, 0% 0, 0% 100%, 100% 100%,
            0 0, 0 0, 0 100%, 100% 0;
        background-size: .1rem 100%, 0% .1rem, 0% .1rem, .1rem 0%,
            .1rem 100%, 100% .1rem, 100% .1rem, .1rem 100%;
    }

    100% {
        background-position: 0% 0%, 0 0, 0 100%, 100% 100%,
            0 0, 0 0, 0 100%, 100% 0;
        background-size: .1rem 0%, 100% .1rem, 0% .1rem, .1rem 0%,
            .1rem 100%, 100% .1rem, 100% .1rem, .1rem 100%;
    }
}

.nav-logo img, 
.nav-logo h1 {
    filter: brightness(.60);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.nav-logo:hover h1, 
.nav-logo:hover img {
    filter: brightness(.80);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.nav-bar-buttons {
    display: flex;
    justify-content: flex-end;
    margin: 1rem 2rem;
    margin-left: auto;
}

.nav-logo img {
    margin-left: .5rem;
}

.nav-logo h1 {
    color: rgb(255, 255, 255);
    font-size: 2.5rem;
    margin: .3rem .5rem;
    text-align: center;
    font-family: 'Montserrat Alternates';
}

.nav-bar a {
    text-decoration: none;
}

.nav-bar-buttons>a {
    position: relative;
    color: rgb(150, 150, 150);
    font-size: 1.2rem;
    margin: 1.3rem 1rem;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
}

.nav-bar-buttons>a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: .1rem;
    bottom: 1.6rem;
    left: 0;
    background-color: rgb(150, 150, 150);
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.nav-bar-buttons>a:hover {
    color: #d6d6d6;
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.nav-bar-buttons>a:hover:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}