#footer {
    display: flex;
    justify-content: space-between;
    height: 4rem;
    position: relative;
    margin: auto;
    min-width: 1200px;
    max-width: 1920px;
    z-index: 10;
    border-top: .05rem solid rgb(36, 35, 35); 
}

#footer-icons {
    margin: 1.3rem;

}

.footer-icon i {
    background-color: rgb(94, 94, 94);
    border-radius: 50%;
    text-align: center;
    font-size: 1.2rem;
    padding: .8rem;
    position: block;
    margin: 0 .5rem;
    text-decoration: none;
    color: rgb(180, 180, 180);
}

.footer-icon i:hover {
    background-color: rgb(119, 119, 119);
    color: rgb(216, 216, 216);
}

.copyright p{
    color: rgb(124, 124, 124);
    margin:  2rem 2.5rem;
    display: flex;
    right: 0;
}