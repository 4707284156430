#about-body {
    position: inline;
    min-width: 1200px;
    max-width: 1920px;
    width: 100%;
}

#about-info {
    position: relative;
    margin: 8rem 3rem 3rem 3rem;
    width: 40%;
    z-index: 15;
}

#about-info h1 {
    color: rgb(93, 38, 165);
    text-align: left;
    font-family: 'Lato', sans-serif;
    font-size: 4.7rem;
    margin: 4rem 0 2rem 0;
    width: 100%;
}

#about-info p {
    color: rgb(150, 150, 150);
    z-index: 15;
    font-size: 1.2rem;
}

.about-michael-img {
    top:0;
    position: absolute;
    background-size: cover;
    min-width: 1200px;
    max-width: 1920px;
    width: 100%;
    -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    filter: grayscale(100%);
}

.about-download-container {
    margin: 1rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    /* width: 26rem; */
}

.about-download-container a {
    text-decoration: none;
    color: rgb(150, 150, 150);
    margin: 1rem 0;
}

.about-download-container a:hover {
    color: rgb(211, 211, 211);
}

.about-download-text {
    margin: 0 5rem;
}

.about-download-text h3 {
    font-family: 'Lato', sans-serif;
    font-size: 1.5rem;
    margin: .5rem 0;
}

.about-icon {
    background-color: rgb(93, 38, 165);
    border-radius: 50%;
    text-align: center;
    font-size: 1.5rem;
    padding: 1.1rem;
    position: absolute;
    width: 1.5rem;
    margin-top: .5rem;
}