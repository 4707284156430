 #development-container {
    overflow: hidden;
}

#development-header-container {
    width: 100%;
    min-width: 1200px;
    max-width: 1920px;
    height: 38rem;
}

#development-header {
    width: 100%;
    height: 50rem;
}

#development-header-title-info-container {
    position: absolute;
    top: 0;
    max-width: 1920px;
    min-width: 800px;
    width: 100%;
}

#development-header-title {
    width: 40rem;
}

#development-header-title h1 {
    position: absolute;
    margin: 15rem 3rem;
    color: #B7C475;
    font-family: 'Lato', sans-serif;
    font-size: 5rem;
    z-index: 13;
}

#development-header-background {
    background-image: url('../assets/typing.gif');
    width: 100%;
    min-width: 1200px;
    max-width: 1920px;
    height: 50rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    filter: grayscale(100%) brightness(60%);
}

.development-header-background-text {
    font-size: 26rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
}

#development-header-info {
    position: relative;
    background-color: rgba(0, 0, 0, 0.50);
    border: .1rem solid #B7C475;
    top: 25rem;
    width: 60rem;
    height: 16rem;
    padding: 2rem;
    margin-left: auto;
    margin-right: auto;
}

#development-header-info h2 {
    position: relative;
    color: #B7C475;
    text-align: left;
    font-family: 'Lato', sans-serif;
    font-size: 1.5rem;
    margin: 0;
}

#development-header-info p {
    color: rgb(150, 150, 150);
    font-size: 1.3rem;
    margin: .8rem;
    margin-bottom: 2rem;
}

#development-header-info img {
    display: flex;
    justify-content: center;
}

#development-header-skills {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 60rem;
    height: 7rem;
    margin-left: auto;
    margin-right: auto;
}

.dev-skill {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    margin: .5rem;
    width: 5rem;
    height: 6rem;
}

.dev-skill img {
    margin-left: auto;
    margin-right: auto;
    height: 50px;
}

.dev-skill h4 {
    color: #B7C475;
    text-align: center;
    margin: .5rem;
    font-size: .7rem;
    font-family: 'Lato', sans-serif;
    height: 3rem;
}

#development-body {
    position: relative;
    justify-content: center;
}

#development-projects-container {
    margin-top: 13rem;
    margin-bottom: 5rem;
    justify-content: center;
}

#development-projects-container h1 {
    font-family: 'Lato', sans-serif;
    color: rgba(183, 196, 117);
    font-size: 2.5rem;
    margin: 2rem 10rem;
}

#development-projects {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: 10%;
    margin-left: 10%;
}

figure.project-tile {
    display: inline-block;
    color: #fff;
    position: relative;
    overflow: hidden;
    margin: .6rem .7rem;
    width: 25rem;
    height: 18rem;
    background: #000000;
    text-align: center;
    border: .1rem solid rgba(183, 196, 117);
    cursor: pointer;
}

figure.project-tile * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

figure.project-tile img {
    opacity: 1;
    width: 100%;
    -webkit-transition: opacity 0.35s;
    transition: opacity 0.35s;
}

figure.project-tile figcaption {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: left;
}

figure.project-tile figcaption>div {
    float: left;
    height: 100%;
    overflow: hidden;
    width: 50%;
    position: relative;
}

figure.project-tile figcaption::before {
    position: absolute;
    top: 50%;
    bottom: 50%;
    left: 50%;
    width: 2px;
    content: '';
    opacity: 0;
    background-color: rgba(255, 255, 255, 0);
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s;
}

figure.project-tile h2,
figure.project-tile h3,
figure.project-tile h4,
figure.project-tile p {
    margin: 0;
    padding: 20px;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    -webkit-transition: opacity 0.45s, -webkit-transform 0.45s;
    transition: opacity 0.45s, -webkit-transform 0.45s, -moz-transform 0.45s, -o-transform 0.45s, transform 0.45s;
}

figure.project-tile h2 {
    font-family: 'Lato', sans-serif;
    text-align: right;
    display: inline-block;
    word-spacing: -0.1em;
    font-weight: 300;
    text-transform: uppercase;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    -webkit-transform: translate3d(50%, 0%, 0);
    transform: translate3d(50%, 0%, 0);
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

figure.project-tile h3 {
    height: 3rem;
    width: 5rem;
    margin-top: 14rem;
    margin-left: 7.6rem;
    padding: .5rem;
    border: .1rem solid #c8c8c8;
    border-right: 0;
    font-family: 'Lato', sans-serif;
    text-align: right;
    display: inline-block;
    word-spacing: -0.1em;
    font-size: 1.3rem;
    font-weight: 300;
    text-transform: uppercase;
    background: rgba(0, 0, 0, 0.5);
    -webkit-transform: translate3d(50%, 0%, 0);
    transform: translate3d(50%, 0%, 0);
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

figure.project-tile h4 {
    height: 3rem;
    width: 4.5rem;
    margin-top: 14rem;
    padding: .5rem;
    border: .1rem solid #c8c8c8;
    border-left: 0;
    font-family: 'Lato', sans-serif;
    text-align: left;
    display: block;
    bottom: 0;
    text-align: left;
    font-weight: 300;
    font-size: 1.3rem;
    top: 0%;
    background: rgba(0, 0, 0, 0.5);
    -webkit-transform: translate3d(-50%, 0%, 0);
    transform: translate3d(-50%, 0%, 0);
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

figure.project-tile h2 span {
    font-weight: 800;
}

figure.project-tile p {
    display: block;
    bottom: 0;
    text-align: left;
    font-weight: 300;
    font-size: 1rem;
    top: 0%;
    color: #000;
    background: rgba(183, 196, 117, 0.849);
    -webkit-transform: translate3d(-50%, 0%, 0);
    transform: translate3d(-50%, 0%, 0);
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

figure.project-tile:hover img {
    opacity: 0.7;
}

figure.project-tile:hover figcaption h2,
figure.project-tile:hover figcaption h3,
figure.project-tile:hover figcaption h4,
figure.project-tile:hover figcaption p {
    -webkit-transform: translate3d(0%, 0%, 0);
    transform: translate3d(0%, 0%, 0);
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
}

figure.project-tile:hover figcaption h2 {
    opacity: 1;
}

figure.project-tile:hover figcaption h3 {
    opacity: 1;
}

figure.project-tile:hover figcaption h4 {
    opacity: 1;
}

figure.project-tile:hover figcaption p {
    opacity: 1;
}

figure.project-tile:hover figcaption::before {
    background: #ffffff;
    top: 0px;
    bottom: 0px;
    opacity: 1;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

#development-modal {
    position: fixed;
    max-width: 1920px;
    min-width: 800px;
    width: 100%;
    height: 100%;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.397);
}

#development-modal-body {
    position: relative;
    height: 90%;
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: .1rem solid rgba(183, 196, 117);
    background-color: #0f0f0f;
    overflow: auto;
}

#development-modal-info {
    margin: 3rem;
    margin-right: 7rem;
}

.close-button {
    top: 6%;
    right: 6%;
    position: fixed;
    z-index: 10;
}

.close-button h3 {
    font-family: 'Lato', sans-serif;
    font-size: 1.5rem;
    margin: 1rem;
    color: rgba(183, 196, 117);
    background-color: #0f0f0f;
    cursor: pointer;
}

.close-button h3:hover {
    color: rgb(212, 228, 131);
}

#development-modal-image {
    margin-left: .5rem;
}

#development-modal-image img{
    width: 600px;
    margin: 1rem;
    border: .1rem solid rgb(187, 187, 187);
}

#development-modal-body h1 {
    font-family: 'Lato', sans-serif;
    font-size: 4rem;
    text-transform: uppercase;
    margin: 1rem;
    color: rgba(183, 196, 117);
}

#development-modal-body h2 {
    font-family: 'Lato', sans-serif;
    font-size: 2rem;
    margin: 1rem;
    color: rgba(183, 196, 117);
}

#development-modal-info p {
    color: #cccccc;
    font-size: 1.2rem;
    margin: 1rem;
}

#development-modal-technologies {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: .5rem;
    margin-top: 2rem; 
}

#modal-other-technologies li {
    color: #cccccc;
    font-size: 1.2rem; 
}

#modal-icons-container {
    display: flex;
    flex-direction: row;
    margin-left: .5rem;
}

.modal-icons {
    display: flex;
    margin: 1.2rem;
    justify-content: center;
}

.modal-icon {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 2rem;
}

.modal-icon:hover {
    background-color: rgb(212, 228, 131);
}

.modal-icons a {
    display: flex;
    flex-direction: column;
    text-decoration: none;
}

.modal-icons i {
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(183, 196, 117);
    border-radius: 50%;
    text-align: center;
    font-size: 2rem;
    padding: .6rem;
    color: #161616;
}

.modal-icons h4 {
    color: rgba(183, 196, 117);
    text-decoration: none;
    font-size: .6rem;
    margin-top: .5rem;
    text-align: center;
}

.modal-icons a:hover > .modal-icon {
    background-color: rgb(212, 228, 131);
}